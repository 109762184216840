import React from "react";

export const HomeIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72358 2.08684C8.86926 1.26707 7.52039 1.26707 6.66607 2.08684L2.12596 6.44333C2.01472 6.55007 1.9399 6.68911 1.91209 6.84075C1.3683 9.80622 1.32816 12.8421 1.79336 15.8209L1.90378 16.528H4.8247V10.4326C4.8247 10.0261 5.15422 9.69656 5.5607 9.69656H10.8289C11.2354 9.69656 11.565 10.0261 11.565 10.4326V16.528H14.4859L14.5963 15.8209C15.0615 12.8421 15.0213 9.80622 14.4776 6.84075C14.4498 6.68911 14.3749 6.55007 14.2637 6.44333L9.72358 2.08684ZM5.6469 1.02471C7.07077 -0.341571 9.31888 -0.341571 10.7427 1.02471L15.2829 5.38121C15.6171 5.70191 15.8419 6.11965 15.9254 6.57525C16.499 9.70339 16.5414 12.9058 16.0507 16.0481L15.8733 17.1839C15.7999 17.6537 15.3953 18 14.9199 18H10.8289C10.4225 18 10.0929 17.6705 10.0929 17.264V11.1686H6.29671V17.264C6.29671 17.6705 5.96719 18 5.5607 18H1.46976C0.994319 18 0.589727 17.6537 0.516367 17.1839L0.338978 16.0481C-0.15174 12.9058 -0.109398 9.70339 0.464225 6.57525C0.54777 6.11965 0.772579 5.70191 1.10679 5.38121L5.6469 1.02471Z"
        fill="currentColor"
      />
    </svg>
  );
};
